<template>
    <div>
        <CRow>
        <CCol md="2">
        </CCol>
        <CCol md="8">

        <ul v-if="fetching">
            <div class="center-align">
                <p><span class="spinner-border" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span></p>
            </div>
        </ul>
        <CCard>
        <CCardHeader>
            <CIcon name="cil-justify-center"/><strong> Contact </strong>
        </CCardHeader>
        <CCardBody>
        <div v-if="!fetching && contact==null">
            <p>Contact {{ $route.params.contactId }} not found</p>
        </div>
        <div v-if="contact">
            <CForm class="needs-validation" novalidate @submit.prevent="submitForm">
                <div v-if="showErrors" :key="showErrors">
                    <CListGroup >
                        <div v-for="(error) in errors" :key="error.message">
                            <CAlert show color="danger">
                            {{ error.message }}
                            </CAlert>
                        </div>
                    </CListGroup>
                </div>
                <div role="group" class="form-group form-row">
                    <label for="nameInputId" class="labelClass col-form-label col-sm-3" >Name</label>
                    <div class="col-sm-9">
                        <input
                            id="nameInputId"
                            class="form-control"
                            type="text"
                            :placeholder="nameField.placeholder"
                            horizontal
                            :readonly="nameField.readonly"
                            v-model="contact.name"
                            :isValid="checkName"
                        />
                        <div class="invalid-feedback" v-if="nameField.error">Name must be at least 3 characters</div>
                        <div v-if="nameField.description">
                            <small class="form-text text-muted w-100">Enter as much of the name as we know</small>
                        </div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="givenNamesInputId" class="labelClass col-form-label col-sm-3" >Given Names</label>
                    <div class="col-sm-9">
                        <input
                            id="givenNamesInputId"
                            class="form-control"
                            type="text"
                            :placeholder="givenNamesField.placeholder"
                            horizontal
                            :readonly="givenNamesField.readonly"
                            v-model="contact.givenNames"
                            :isValid="checkGivenNames"
                        />
                        <div class="invalid-feedback" v-if="givenNamesField.error">Name must be at least 3 characters</div>
                        <div v-if="givenNamesField.description">
                            <small class="form-text text-muted w-100">Enter Given Names</small>
                        </div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="surnameInputId" class="labelClass col-form-label col-sm-3" >Surname</label>
                    <div class="col-sm-9">
                        <input
                            id="surnameInputId"
                            class="form-control"
                            type="text"
                            :placeholder="surnameField.placeholder"
                            horizontal
                            :readonly="surnameField.readonly"
                            v-model="contact.surname"
                            :isValid="checkSurname"
                        />
                        <div class="invalid-feedback" v-if="surnameField.error">Surname must be at least 3 characters</div>
                        <div v-if="surnameField.description">
                            <small class="form-text text-muted w-100">Enter Given Names</small>
                        </div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="emailInputId" class="labelClass col-form-label col-sm-3" >Email</label>
                    <div class="col-sm-9">
                        <input
                            id="emailInputId"
                            class="form-control"
                            type="text"
                            :placeholder="emailField.placeholder"
                            horizontal
                            :readonly="emailField.readonly"
                            v-model="contact.email"
                            :isValid="checkEmail"
                        />
                        <div class="invalid-feedback" v-if="emailField.error">Invalid email address</div>
                        <div v-if="emailField.description">
                            <small class="form-text text-muted w-100">Enter Email Address</small>
                        </div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="phoneInputId" class="labelClass col-form-label col-sm-3" >Phone</label>
                    <div class="col-sm-9">
                        <input
                            id="phoneInputId"
                            class="form-control"
                            type="text"
                            :placeholder="phoneField.placeholder"
                            horizontal
                            :readonly="phoneField.readonly"
                            v-model="contact.phone"
                            :isValid="checkPhone"
                        />
                        <div class="invalid-feedback" v-if="phoneField.error">Invalid phone number</div>
                        <div v-if="phoneField.description">
                            <small class="form-text text-muted w-100">Enter Phone Number</small>
                        </div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="preferredContactTypeInputId" class="labelClass col-form-label col-sm-3" >Preferred Contact</label>
                    <div class="col-sm-9">
                        <input
                            id="preferredContactTypeInputId"
                            class="form-control"
                            type="text"
                            :placeholder="preferredContactTypeField.placeholder"
                            horizontal
                            :readonly="preferredContactTypeField.readonly"
                            v-model="contact.preferredContactType"
                        />
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="address1InputId" class="labelClass col-form-label col-sm-3" >Address Line 1</label>
                    <div class="col-sm-9">
                        <input
                            id="address1InputId"
                            class="form-control"
                            type="text"
                            :placeholder="address1Field.placeholder"
                            horizontal
                            :readonly="address1Field.readonly"
                            v-model="contact.address1"
                            :isValid="checkAddressLine"
                        />
                        <div class="invalid-feedback" v-if="address1Field.error">Invalid address line</div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="address1InputId" class="labelClass col-form-label col-sm-3" >Address Line 2</label>
                    <div class="col-sm-9">
                        <input
                            id="address1InputId"
                            class="form-control"
                            type="text"
                            :placeholder="address2Field.placeholder"
                            horizontal
                            :readonly="address2Field.readonly"
                            v-model="contact.address2"
                            :isValid="checkAddressLine"
                        />
                        <div class="invalid-feedback" v-if="address2Field.error">Invalid address line</div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="cityInputId" class="labelClass col-form-label col-sm-3" >City</label>
                    <div class="col-sm-9">
                        <input
                            id="cityInputId"
                            class="form-control"
                            type="text"
                            :placeholder="cityField.placeholder"
                            horizontal
                            :readonly="cityField.readonly"
                            v-model="contact.city"
                            :isValid="checkCity"
                        />
                        <div class="invalid-feedback" v-if="cityField.error">Invalid City</div>
                    </div>
                </div>

                <div role="group" class="form-group form-row">
                    <label for="postcodeInputId" class="labelClass col-form-label col-sm-3" >Postcode</label>
                    <div class="col-sm-9">
                        <input
                            id="postcodeInputId"
                            class="form-control"
                            type="text"
                            :placeholder="postcodeField.placeholder"
                            horizontal
                            :readonly="postcodeField.readonly"
                            v-model="contact.postcode"
                            :isValid="checkPhone"
                        />
                        <div class="invalid-feedback" v-if="postcodeField.error">Invalid Postcode</div>
                    </div>
                </div>

            </CForm>

        </div>
        </CCardBody>
        </CCard>
        </CCol>
        </CRow>

        <div v-if="errors && errors.length">
            <b-list-group-item v-for="error in errors" v-bind:key="error.message" href="javascript:;" class=" flex-column align-items-start py-4 px-4">
            {{error.message}}
            </b-list-group-item>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Contact',
    components: {
  },
  data() {
    return {
      contact: null,
      errors: [],
      fetching: false,
      nameField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      givenNamesField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      surnameField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      emailField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      phoneField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      preferredContactField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      address1Field: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      address2Field: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      cityField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      postcodeField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      preferredContactTypeField: {
          description: false,
          readonly: true,
          placeholder: null,
          error: false
      },
      editMode: false,
      showErrors: false
    }
  },
  methods: {
    fetchContact () {
        console.log('fetch contact now baby!!!')
        console.log('process.env.VUE_APP_CRM_API_ROOT: ' + process.env.VUE_APP_CRM_API_ROOT)
        console.log('contactId: ' + this.$route.params.contactId)
        const url = process.env.VUE_APP_CRM_API_ROOT + '/contacts/' + this.$route.params.contactId;
        console.log('url: ' + url)
        console.log('fetch contact')
        this.fetching = true;
        this.$cognitoAuth.getIdToken((err, result) => {
            if (err) { 
                this.errors.push(err);
            } else {
                console.log('Contact, axios token: ' + result)
                this.$http.defaults.headers.common['Authorization'] = result;
                axios({ method: 'get', url: url})
                    .then(response => {
                        console.log('fetch contact, response: ' + JSON.stringify(response.data))
                        this.contact = response.data
                        this.fetching = false;
                        delete this.$http.defaults.headers.common['Authorization'];  // why is this here?
                    })
                    .then(status => {
                        console.log('fetch contact, axios status: ' + status)
                        this.status = status;
                    })
                    .catch(err => {
                        console.log('fetch contact, axios err: ' + err)
                        this.fetching = false;
                        this.errors.push(err)
                    })
            }
        })
    },
    checkName(name) {
        if (!this.editMode)
            return null
        if (name) {
            return name.length > 2
        }
        return null
    },
    checkGivenNames(name) {
        if (!this.editMode)
            return null
        if (name) {
            return name.length > 2
        }
        return null
    },
    checkSurname(name) {
        if (!this.editMode)
            return null
        if (name) {
            return name.length > 2
        }
        return null
    },
    checkEmail(email) {
        if (!this.editMode)
            return null
        if (email) {
            return email.length > 2
        }
        return null
    },
    checkPhone(phone) {
        if (!this.editMode)
            return null
        if (phone) {
            return phone.length > 2
        }
        return null
    },
    checkAddressLine(addressLine) {
        if (!this.editMode)
            return null
        if (addressLine) {
            return adressLine.length > 2
        }
        return null
    },
    checkCity(city) {
        if (!this.editMode)
            return null
        if (city) {
            return city.length > 2
        }
        return null
    },
    checkPostcode(postcode) {
        if (!this.editMode)
            return null
        if (postcode) {
            return postcode.length > 2
        }
        return null
    },
    setFieldProperties() {
        if (this.editMode) {
            this.nameField.placeholder = 'Name'
            this.nameField.description = "Enter full name here or enter separate Given Names and Surname in individual fields"
            this.nameField.readonly = false
            this.givenNamesField.placeholder = 'Given Names'
            this.givenNamesField.description = "Enter Given Names here or enter full name in Name field"
            this.givenNamesField.readonly = false
            this.surnameField.placeholder = 'Given Names'
            this.surnameField.description = "Enter Surname here or enter full name in Name field"
            this.surnameField.readonly = false
        } else {
            this.nameField.placeholder = ''
            this.nameField.description = ''
            this.nameField.readonly = true
            this.givenNamesField.placeholder = ''
            this.givenNamesField.description = ''
            this.givenNamesField.readonly = true
            this.surnameField.placeholder = ''
            this.surnameField.description = ''
            this.surnameField.readonly = true
        }
    }

  },
  // Fetches posts when the component is created.
  mounted() {
        console.log('call fetchContact!!!')
        this.fetchContact()
        this.setFieldProperties()
        console.log('Contact:created')
  }

}
</script>

<style>
.contact-row {
    padding-bottom: 10px;
}

.labelClass {
  text-align: right;
}
</style>
